import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

const OuterConatiner = styled.div`
  background: #fcb3ae;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  height: 100%;
  @media (max-width: 768px) {
    height: auto;
  }
`;
const InnerContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
`;
const DescriptionText = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
`

export default function WhatWeOfferCard({image,description}) {
  return (
    <OuterConatiner>
      <InnerContainer>
        <Stack spacing={3} alignItems="center">
          <img src={image} alt="pgpp" width="150px" />
          <DescriptionText>{description}</DescriptionText>
        </Stack>
      </InnerContainer>
    </OuterConatiner>
  );
}
