import React, { useCallback } from "react";
import cn from "classnames";
import BlockQuote from "../BlockQuote";

const ImageTestimonial = ({
  children,
  className,
  name,
  designation,
  image,
  link,
}) => {
  const handleClick = useCallback(() => {
    if (link) {
      window.open(link, "_blank");
    }
  }, [link]);

  return (
    <div className={cn({ [className || ""]: className })}>
      <div className="flex flex-col items-center justify-between lg:flex-row">
        <div className="w-8" />
        <div className="hidden pl-10 mt-8 lg:mt-0 w-100 lg:block">
          <img
            src={image}
            alt="Institutional Collaboration with IIDL"
            style={{
              borderRadius: 8,
              height: "380px",
              maxWidth: "100%",
              objectFit: "contain",
            }}
          />
        </div>
        <div className="w-full pt-6 pl-10 mb-10 lg:pl-10 lg:w-3/5 pb-[40px]">
          <BlockQuote className="pl-12 text-justify grow-0 w-100" fontScaling>
            {children}
          </BlockQuote>

          <footer className="mt-6 ml-12">
            <div
              className={cn("inline-flex items-center", {
                "cursor-pointer": !!link,
              })}
              onClick={handleClick}
            >
              <div className="inline-flex flex-shrink-0 mr-4 border-2 border-white rounded-full lg:hidden">
                <img className="w-16 h-16 rounded-full" src={image} alt="" />
              </div>
              <div>
                <div className="text-xl font-semibold text-footerBlack">
                  {name}
                </div>
                <div className="mt-1 text-sm text-txtGrey opacity-70">
                  {designation}
                </div>
              </div>
            </div>
          </footer>
        </div>

        <div className="w-16" />
      </div>
    </div>
  );
};

export default ImageTestimonial;
