import React from 'react'
import styled from "styled-components";

const TitleText = styled.p`
  color: #101010;
  font-size: 25px;
  margin: 0px;
  text-align: left;
  font-weight: 900;
  border-bottom: 1px solid rgb(185 177 177);
  margin-bottom: 15px;
  padding-bottom: 15px;
`;
const DescriptionText = styled.p`
  color: #101010;
  font-size: 20px;
  margin: 0px;
  text-align: left;
  font-weight: 500l;
`;
export default function FaqCard({question, answer}) {
  return (
    <div style={{marginBottom: '25px'}}>
      <TitleText>{question}</TitleText>
      <DescriptionText dangerouslySetInnerHTML={{ __html: answer }} />
    </div>
  );
}
