import React from "react";
import styled from "styled-components";
import FormControl from "@mui/material/FormControl";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack, Container, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import applyNowBanner from "../../assets/images/applyNowBanner.png";
import states from "../../assets/states.json";
import dayjs from "dayjs";

const RibbonContainer = styled.div`
  max-width: 400px;
  width: 400px;
  background: rgb(1, 170, 248);
  height: 60px;
  clip-path: polygon(0px 0%, 100% 0px, 90% 100%, 0px 100%);
  position: absolute;
  bottom: -30px;
  left: 0px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;

const UploadButton = styled(Button)`
  background: #f3f5f9 !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  filter: drop-shadow(2.83px 2.83px 4px #b1b1b1) !important;
  font-family: Montserrat !important;
  min-height: 65px;
  border-radius: 15px !important;
  font-size: 23px !important;
  max-width: 275px;
  color: #101010 !important;
`;
const StyledButton = styled(Button)`
  background: ${({ disabled }) =>
    disabled ? "rgb(230 236 235)" : "#ff391e"} !important;
  border-radius: 15px !important;
  color: ${({ disabled }) => (disabled ? "#000000" : "white")} !important;
  font-family: Montserrat !important;
  font-size: 23px !important;
  margin-top: 30px !important;
  min-height: 65px;
  max-width: 275px;
`;
const TitleText = styled.p`
  color: #101010;
  font-size: 54px;
  margin: 0px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const DividerWithLine = styled.p`
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  max-width: 300px;
  flex-direction: row;
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-right: 0px;
    max-width: 80px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-left: 0px;
    max-width: 80px;
  }
`;

const DividerBlock = styled.div`
  width: 120px;
  background: #ffffff;
  height: 8px;
`;
const LabelText = styled.p`
  color: #101010;
  font-size: 23px;
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 600;
`;

const SubtitleLi = styled.li`
  color: #101010;
  font-size: 19px;
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 400;
`;
const SubtitleText = styled.p`
  color: #101010;
  font-size: 19px;
  margin: 0px;
  margin-bottom: 15px;
  font-weight: 400;
`;
const ApplyContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 96px;
  }
`;
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 10,
    position: "relative",
    backgroundColor: "#F3F5F9",
    fontSize: 16,
    minHeight: "45px !important",
    width: "100%",
    padding: "10px 12px",
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
}));

const errorMsgs = {
  invalidDate: 'Please enter a valid date.',
  minDate: 'Age limit exceeded. DOB cannot be before 01/07/1998.',
  maxDate: 'You\'re too young to apply for the internship. DOB cannot be later than 01/07/2006.',
}

export default function ApplyNowPage() {
  const [dateError, setDateError] = React.useState(null)
  const [userData, setUserData] = React.useState({
    name: "",
    email: "",
    phone: "",
    homeState: "",
    gender: "",
    dob: null,
    graduationYear: "",
    educationalInstitute: "",
    resumeFile: null,
    purposeFile: null,
  });
  const handleTextChanges = (title, value) => {
    setUserData((userData) => ({
      ...userData,
      [title]: value,
    }));
  };
  const handleFile = (title, event) => {
    setUserData((userData) => ({
      ...userData,
      [title]: event.target.files[0],
    }));
  };
  let disableButton = true;
  if (
    userData.name &&
    userData.name.length > 0 &&
    dayjs(userData.dob).isValid() &&
    !dateError &&
    userData.email &&
    userData.email.length > 0 &&
    userData.phone &&
    userData.phone.length > 0 &&
    userData.homeState &&
    userData.homeState.length > 0 &&
    userData.gender &&
    userData.gender.length > 0 &&
    userData.graduationYear &&
    userData.educationalInstitute &&
    userData.educationalInstitute.length > 0 &&
    userData.resumeFile &&
    userData.purposeFile
  ) {
    disableButton = false;
  }
  return (
    <ApplyContainer>
      {" "}
      <div style={{ position: "relative", marginBottom: "50px" }}>
        <img
          src={applyNowBanner}
          alt="pgpp"
          width="100%"
          style={{ maxHeight: "480px" }}
        />
        <RibbonContainer />
      </div>
      <Container maxWidth="lg">
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ marginBottom: "30px" }}
        >
          <TitleText>Application to the Mukherjee Fellowship</TitleText>
          <DividerWithLine color="#FF391E">
            <DividerBlock style={{ background: "#FF391E" }} />
          </DividerWithLine>
        </Stack>
        <form
          action="https://formsubmit.co/mukherjee.fellowship@ppgf.in"
          method="POST"
          target="_blank"
          encType="multipart/form-data"
        >
          <Stack spacing={3} sx={{ margin: "20px 0px" }}>
            <FormControl variant="standard" fullWidth>
              <LabelText>Full Name</LabelText>
              <BootstrapInput
                fullwidth
                id="full-name"
                name="Full Name"
                value={userData.name}
                onChange={(event) =>
                  handleTextChanges("name", event.target.value)
                }
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>E-mail ID</LabelText>
              <BootstrapInput
                fullwidth
                name="E-mail ID"
                type="email"
                id="email"
                value={userData.email}
                onChange={(event) =>
                  handleTextChanges("email", event.target.value)
                }
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>Phone Number</LabelText>
              <BootstrapInput
                fullwidth
                id="phone"
                type="number"
                name="Phone Number"
                value={userData.phone}
                onChange={(event) =>
                  handleTextChanges("phone", event.target.value)
                }
              />
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>Home State</LabelText>
              <Select
                name="Home State"
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={userData.homeState}
                onChange={(event) =>
                  handleTextChanges("homeState", event.target.value)
                }
                input={<BootstrapInput />}
              >
                {states.map((state) => (
                  <MenuItem value={state.name}>{state.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>Gender</LabelText>
              <Select
                Home
                name="Gender"
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={userData.gender}
                onChange={(event) =>
                  handleTextChanges("gender", event.target.value)
                }
                input={<BootstrapInput />}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Prefer Not to Say</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>Date of Birth</LabelText>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  format="DD/MM/YYYY"
                  value={userData.dob}
                  onChange={(event) =>
                    handleTextChanges("dob", new Date(event?.toDate()))
                  }
                  sx={{
                    background: "#F3F5F9",
                    border: "none",
                    borderRadius: "10px",
                  }}
                  minDate={dayjs("1998-07-1")}
                  maxDate={dayjs("2006-07-1")}
                  slotProps={{
                    textField: { name: "Date of Birth" },
                  }}
                  onError={(error) => {
                    setDateError(error)
                  }}
                />
              </LocalizationProvider>
              {!!dateError && <span style={{ color: 'red', marginTop: 4 }}>{errorMsgs[dateError]}</span>}
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>
                Graduation Year
                <span
                  style={{
                    fontSize: "19px",
                    marginLeft: "5px",
                    fontWeight: "400",
                  }}
                >
                  (Year of graduation from the most recent academic degree)
                </span>
              </LabelText>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                sx={{ minHeight: "45px !important" }}
                value={userData.graduationYear}
                onChange={(event) =>
                  handleTextChanges("graduationYear", event.target.value)
                }
                input={<BootstrapInput />}
                name="Graduation Year"
              >
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((yearToAdd) => (
                  <MenuItem key={yearToAdd.toString()} value={2015 + yearToAdd}>
                    {2015 + yearToAdd}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth>
              <LabelText>
                Educational Institution
                <span
                  style={{
                    fontSize: "19px",
                    marginLeft: "5px",
                    fontWeight: "400",
                  }}
                >
                  (Name of the educational institution enrolled in for the most
                  recent academic degree)
                </span>
              </LabelText>
              <BootstrapInput
                fullwidth
                id="educationalInstitute"
                name="Educational Institute"
                value={userData.educationalInstitute}
                onChange={(event) =>
                  handleTextChanges("educationalInstitute", event.target.value)
                }
              />
            </FormControl>
            <div>
              <LabelText>Upload CV/Resume</LabelText>
              <Stack direction="row" spacing={2}>
                <UploadButton
                  variant="contained"
                  component="label"
                  onChange={(event) => handleFile("resumeFile", event)}
                >
                  Choose File
                  <input
                    hidden
                    name="cv"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    type="file"
                  />
                </UploadButton>
                {userData.resumeFile && (
                  <SubtitleText style={{ marginTop: "20px" }}>
                    {userData.resumeFile.name}
                  </SubtitleText>
                )}
              </Stack>
              <SubtitleText style={{ marginTop: "20px" }}>
                Only .pdf .doc or .docx files are allowed
              </SubtitleText>
            </div>
            <div>
              <LabelText>Upload Statement of Purpose</LabelText>
              <LabelText style={{ fontSize: "20px" }}>
                Please draft a brief (average 500 words) personal statement
                that:
              </LabelText>
              <ol>
                <SubtitleLi>
                  Explains your interest in this Fellowship.
                </SubtitleLi>
                <SubtitleLi>
                  Describes how this Fellowship will help you to achieve any
                  future plans.
                </SubtitleLi>
                <SubtitleLi>
                  Describes how you will be able to contribute to the fellowship
                  and to the policy making space.
                </SubtitleLi>
              </ol>
              <Stack direction="row" spacing={2}>
                <UploadButton
                  variant="contained"
                  component="label"
                  onChange={(event) => handleFile("purposeFile", event)}
                >
                  Choose File
                  <input
                    hidden
                    name="Purpose File"
                    accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    type="file"
                  />
                </UploadButton>
                {userData.purposeFile && (
                  <SubtitleText style={{ marginTop: "20px" }}>
                    {userData.purposeFile.name}
                  </SubtitleText>
                )}
              </Stack>
              <SubtitleText style={{ marginTop: "20px" }}>
                Only .pdf .doc or .docx files are allowed
              </SubtitleText>
            </div>
            <StyledButton
              endIcon={<ArrowForwardIcon />}
              size="large"
              type="submit"
              disabled={disableButton}
            >
              SUBMIT
            </StyledButton>
          </Stack>
        </form>
      </Container>
    </ApplyContainer>
  );
}
