import { Stack } from "@mui/material";
import React from "react";
import styled from "styled-components";

const DescriptionText = styled.p`
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #101010;
`;

const ModuleCardContainer = styled.div`
  background: #feeae9;
  border: 1px solid #fcb3ae;
  padding: 20px;
  border-radius: 20px;
  max-width: 480px;
`;
const Title = styled.p`
  color: #ff391e;
  font-size: 30px;
  margin: 0px;
  font-weight: 900;
  text-align: center;
`;
const DividerLine = styled.div`
  border-bottom: 1px solid #ff391e;
  max-width: 200px;  
  width: 100%;
`;
export default function ModuleCard({ image, description, title }) {
  return (
    <ModuleCardContainer>
      <Stack spacing={2} alignItems="center">
        <img src={image} alt="pgpp" width="150px" />
        <Title>{title}</Title>
        <DividerLine />
        <DescriptionText>{description}</DescriptionText>
      </Stack>
    </ModuleCardContainer>
  );
}
