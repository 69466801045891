import React from "react";
import styled from "styled-components";
import { Stack, Container, Grid } from "@mui/material";

import FaqCard from "../../components/FaqCard";

import FaqBanner from "../../assets/images/FaqBanner.png";
import faqs from "../../assets/faq.json";
import vectorImg from "../../assets/images/Vector Smart Object.png";

const RibbonContainer = styled.div`
  max-width: 400px;
  width: 400px;
  background: rgb(1, 170, 248);
  height: 60px;
  clip-path: polygon(0px 0%, 100% 0px, 90% 100%, 0px 100%);
  position: absolute;
  bottom: -30px;
  left: 0px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;
const TitleText = styled.p`
  color: #101010;
  font-size: 63px;
  margin: 0px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const TitleTextMoreQueries = styled.p`
  color: #ffffff;
  font-size: 50px;
  margin: 0px;
  text-align: left;
  font-weight: 900;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const DividerWithLine = styled.p`
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  max-width: 300px;
  flex-direction: row;
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-right: 0px;
    max-width: 80px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-left: 0px;
    max-width: 80px;
  }
`;

const DividerBlock = styled.div`
  width: 120px;
  background: #ffffff;
  height: 8px;
`;
const FaqContainer = styled.div`
  @media (max-width: 768px) {
    margin-top: 96px;
  }
`;
export default function FaqPage() {
  return (
    <FaqContainer>
      <div style={{ position: "relative", marginBottom: "50px" }}>
        <img
          src={FaqBanner}
          alt="pgpp"
          width="100%"
          style={{ maxHeight: "480px" }}
        />
        <RibbonContainer />
      </div>
      <Container maxWidth="lg">
        <Stack alignItems="center" justifyContent="center">
          <TitleText>Frequently Asked Questions</TitleText>
          <DividerWithLine color="#FF391E">
            <DividerBlock style={{ background: "#FF391E" }} />
          </DividerWithLine>
        </Stack>
        {faqs.map((faq) => (
          <FaqCard {...faq} />
        ))}
      </Container>
      <div
        style={{
          padding: "30px 10px",
          marginTop: "50px",
          background: "linear-gradient(to bottom, #FC686F, #FF934C)",
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack justifyContent="center" height="100%" spacing={4}>
                <TitleTextMoreQueries>More Queries</TitleTextMoreQueries>
                <DividerWithLine>
                  <DividerBlock />
                </DividerWithLine>
                <TitleText
                  style={{
                    fontSize: "23px",
                    color: "#ffffff",
                    fontStyle: "italic",
                    fontWeight: 700,
                    textAlign: "left",
                  }}
                >
                  In case of further queries, please mail your questions and
                  apprehensions to career@ppgf.in
                </TitleText>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                src={vectorImg}
                alt="vector"
                width="100%"
                style={{
                  maxWidth: "500px",
                  height: "500px",
                  objectFit: "contain",
                }}
              />
            </Grid>
          </Grid>
        </Container>
      </div>
    </FaqContainer>
  );
}
