import React from "react";
import Grid from "@mui/material/Grid";
import styled from "styled-components";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Stack, Button, Container } from "@mui/material";
import { withRouter } from "react-router";

import WhatWeOfferCard from "../../components/WhatWeOfferCard";
import ModuleCard from "../../components/ModuleCard";

import Herobackground from "../../assets/Herobackground.png";
import designedByLogo from "../../assets/images/designedByLogo.png";
import whatWeOfeerBackGroundImg from "../../assets/images/whatWeOfeer.png";
import equipLogo from "../../assets/images/Equip.png";
import experienceLogo from "../../assets/images/experience.png";
import rolesLogo from "../../assets/images/roles.png";
import experimentalModule from "../../assets/images/learningModule2.png";
import theroticalModule from "../../assets/images/learningModule.png";
import theroticalModukeImg from "../../assets/images/Presidents-Estate.gif";
import experimentalModuleImg from "../../assets/images/parliment.jpg";
import fellowShipbackgroundImg from "../../assets/images/fellowshipBackground.png";
import { testimonials } from "../../config";
import Testimonial from "../../components/Testimonials";

const HeroContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 600px;
  background-image: url(${Herobackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 90px;
  }
`;
const HidePhone = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const HideBigScreen = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;
const ImageContainer = styled.div`
  filter: drop-shadow(2.83px 2.83px 4px #b1b1b1);
  padding: 5px;
  background: #e9ecef;
  border-radius: 20px;
  width: 100%;
`;
const ModuleOuterContainer = styled.div`
  margin-top: 220px;
  position: relative;
  margin-bottom: 100px;
  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
`;
const HeroContainerWhatWeOffer = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
  min-height: 600px;
  margin-top: 20px;
  margin-bottom: 30px;
  background-image: url(${whatWeOfeerBackGroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`;
const FellowShipContainer = styled.div`
  padding: 40px;
  display: flex;
  justify-content: center;
  min-height: 600px;
  background-image: url(${fellowShipbackgroundImg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;
const HeroSubTitle = styled.p`
  font-size: 27px;
  font-style: normal;
  text-align: justify;
  color: #ffffff;
  margin: 0px;
  line-height: 40px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;
const TitleText = styled.p`
  color: #ffffff;
  font-size: 63px;
  margin: 0px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const TitleTextForFelloShip = styled.p`
  color: #010101;
  padding: 0px 15px;
  font-size: 57px;
  margin: 0px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const TitleWithLine = styled.p`
  display: flex;
  flex-direction: row;
  color: #ffffff;
  font-size: 63px;
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #ffffff;
    margin: auto;
    margin-right: 10px;
    max-width: 80px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
    margin-left: 10px;
    max-width: 80px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;
const DividerWithLine = styled.p`
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  &:before {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-right: 0px;
    max-width: 80px;
  }
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: ${({ color }) =>
      color ? `2px solid ${color}` : "2px solid #ffffff"};
    margin: auto;
    margin-left: 0px;
    max-width: 80px;
  }
`;
const StyledButton = styled(Button)`
  background: #ff381e !important;
  border-radius: 5px !important;
  color: white !important;
  font-family: Montserrat !important;
  font-size: 18px !important;
  min-height: 65px;
  padding: 15px 40px !important;
`;

const RibbonContainer = styled.div`
  max-width: 400px;
  width: 400px;
  background: rgb(1, 170, 248);
  height: 60px;
  clip-path: polygon(0px 0%, 100% 0px, 90% 100%, 0px 100%);
  position: absolute;
  bottom: -30px;
  left: 0px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;
const RibbonContainerRight = styled.div`
  max-width: 400px;
  width: 400px;
  background: rgb(1, 170, 248);
  height: 60px;
  clip-path: polygon(0 0%, 100% 0%, 100% 100%, 20% 100%);
  position: absolute;
  bottom: -30px;
  right: 0px;
  @media (max-width: 768px) {
    width: 200px;
  }
`;
const DividerBlock = styled.div`
  width: 120px;
  background: #ffffff;
  height: 8px;
`;
const StyledLi = styled.li`
  font-size: 21px;
  line-height: 30px;
  text-align: justify;
  &::marker {
    color: #ff391e;
  }
`;
function Home(props) {
  return (
    <div>
      <HeroContainer>
        <Stack spacing={2}>
          <HeroSubTitle>
            Mukherjee Fellowship presents you with the opportunity to
          </HeroSubTitle>
          <TitleWithLine>CREATE THE FUTURE!</TitleWithLine>
          <Stack
            spacing={3}
            alignItems={{
              sm: "center",
              xm: "center",
              md: "flex-end",
              lg: "flex-end",
            }}
            justifyContent="center"
            direction={{
              xs: "column",
              sm: "column",
              md: "row",
              xl: "row",
            }}
          >
            <Stack justifyContent="center" alignItems="center">
              <HeroSubTitle style={{ fontSize: "15px" }}>
                Designed By
              </HeroSubTitle>
              <img
                src={designedByLogo}
                alt="ppgf"
                style={{ maxWidth: "250px" }}
              />
            </Stack>
            <StyledButton
              endIcon={<ArrowForwardIcon />}
              size="large"
              // onClick={() => props.history.push("/apply")}
              href="https://docs.google.com/forms/d/e/1FAIpQLSeoI2xMFWQfgvJCUPKEM8wMjMvvGLiPYUI5wkwx56j9OM1_2g/viewform"
              target="_blank"
            >
              APPLY NOW
            </StyledButton>
          </Stack>
        </Stack>
        <RibbonContainer />
      </HeroContainer>
      <Container maxWidth="xl">
        <div
          style={{ marginTop: "50px", textAlign: "center", padding: "20px" }}
        >
          <HeroSubTitle style={{ fontSize: "24px", color: "#000000" }}>
            <span style={{ fontWeight: "900", fontSize: "24px" }}>
              Policy, Politics and Governance Foundation (PPGF)
            </span>
            , in collaboration with{" "}
            <span style={{ fontWeight: "900", fontSize: "24px" }}>
              Nation First Policy Research Centre (NFPRC)
            </span>
            , is offering a unique fellowship opportunity to provide young
            professionals across India a hands-on policy, politics and
            governance experience. Working closely with premier elected
            representatives and administrators in the country for a year,
            Fellows stand to gain insights into the world of policymaking and
            active politics. Working with subject matter experts on a wide range
            of roles ranging from legislative briefing to active social media
            management, the Fellowship aims to set young professionals up for a
            successful career in the space.
          </HeroSubTitle>
        </div>
      </Container>
      <HeroContainerWhatWeOffer>
        <Container maxWidth="xl">
          <TitleText>What do we offer?</TitleText>
          <DividerWithLine>
            <DividerBlock />
          </DividerWithLine>
          <Grid container spacing={3} alignItems="stretch">
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <WhatWeOfferCard
                image={equipLogo}
                description="Equip yourself with the basic tools to work with elected representatives and administrators (in and out of government)."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <WhatWeOfferCard
                image={experienceLogo}
                description="Gain the experience of working with premier elected representatives and being at the heart of policy-making and governance in the country."
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <WhatWeOfferCard
                image={rolesLogo}
                description="Take up potential full-time roles in leading policy research institutions as well as the personal offices of the premier elected representatives in the country, at the end of the Fellowship."
              />
            </Grid>
          </Grid>
        </Container>
      </HeroContainerWhatWeOffer>
      <Container maxWidth="xl">
        <div style={{ marginBottom: "30px" }}>
          <TitleTextForFelloShip>
            What is the duration of the Mukerjee Fellowship and what will it
            entail?{" "}
          </TitleTextForFelloShip>
          <DividerWithLine color="#FF391E">
            <DividerBlock style={{ background: "#FF391E" }} />
          </DividerWithLine>
          <HidePhone>
            <Stack
              alignItems="center"
              style={{
                background: "#EDEDED",
                borderRadius: "10px",
                padding: "20px",
                position: "relative",
                minHeight: "366px",
              }}
            >
              <HeroSubTitle
                style={{
                  color: "#010101",
                  padding: "0px 15px",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                Fellows will be engaged for 12 months. The Fellowship itself is
                spread over two modules-
              </HeroSubTitle>
              <div
                style={{
                  padding: "0px 15px",
                  position: "absolute",
                  bottom: "-150px",
                }}
              >
                <Grid
                  container
                  spacing={2}
                  alignItems="stretch"
                  justifyContent="center"
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    display="flex"
                    justifyContent="center"
                  >
                    <ModuleCard
                      image={theroticalModule}
                      title="Theoretical Learning Module"
                      description="A 1.5 month-long in-person training module focussed on introducing diverse perspectives to the Fellows that will be conducted in Delhi."
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    display="flex"
                    justifyContent="center"
                  >
                    <ModuleCard
                      image={experimentalModule}
                      title="Experiential Learning Module"
                      description="A 10.5 month long experience, involving deputation to the office of MPs post the completion of the learning module."
                    />
                  </Grid>
                </Grid>
              </div>
            </Stack>
          </HidePhone>
          <HideBigScreen>
            <Stack
              alignItems="center"
              style={{
                background: "#EDEDED",
                borderRadius: "10px",
                padding: "20px",
                position: "relative",
                minHeight: "250px",
              }}
            >
              <HeroSubTitle
                style={{
                  color: "#010101",
                  padding: "0px 15px",
                  textAlign: "center",
                  marginBottom: "40px",
                }}
              >
                Fellows will be engaged for 12 months. The Fellowship itself is
                spread over two modules-
              </HeroSubTitle>
              <Grid
                container
                spacing={2}
                alignItems="stretch"
                justifyContent="center"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  display="flex"
                  justifyContent="center"
                >
                  <ModuleCard
                    image={theroticalModule}
                    title="Theoretical Learning Module"
                    description="A 1.5 month-long in-person training module focussed on introducing diverse perspectives to the Fellows that will be conducted in Delhi."
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={6}
                  display="flex"
                  justifyContent="center"
                >
                  <ModuleCard
                    image={experimentalModule}
                    title="Experiential Learning Module"
                    description="A 10.5 month long experience, involving deputation to the office of MPs post the completion of the learning module."
                  />
                </Grid>
              </Grid>
            </Stack>
          </HideBigScreen>
        </div>
      </Container>
      <ModuleOuterContainer>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={5}
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <HeroSubTitle
                style={{
                  color: "#010101",
                  fontSize: "40px",
                  marginBottom: "20px",
                  fontWeight: "900",
                  textAlign: "left",
                }}
              >
                Theoretical Learning Module
              </HeroSubTitle>
              <ol>
                <StyledLi>
                  The learning module will last for a period of one and half
                  months starting on 1st July and will take place in person in
                  Delhi with the attendance of all the Fellows being compulsory.{" "}
                </StyledLi>
                <StyledLi>
                  The learning module will contain 7 submodules and 2
                  specialized sub modules, each of which will include an overall
                  guide presentation, sample documents, books for assigned
                  readings and a set of assignments that are to be completed for
                  the completion of the sub module.{" "}
                </StyledLi>
                <StyledLi>
                  The contents of these sub modules will have to be completed
                  within the period of one and half months. A certain minimum
                  accomplishment needs to be attained in each sub module for the
                  purpose of passage to the next module.{" "}
                </StyledLi>
              </ol>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ImageContainer>
                <img
                  src={theroticalModukeImg}
                  width="100%"
                  alt="pgpp"
                  style={{ borderRadius: "20px" }}
                />
              </ImageContainer>
            </Grid>
          </Grid>
        </Container>
      </ModuleOuterContainer>

      <div
        style={{
          marginTop: "30px",
          position: "relative",
          background: "#F6F6F7",
          paddingBottom: "60px",
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            spacing={5}
            alignItems="stretch"
            justifyContent="center"
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              display="flex"
              alignItems="center"
            >
              <ImageContainer>
                <img
                  src={experimentalModuleImg}
                  width="100%"
                  alt="pgpp"
                  style={{ borderRadius: "20px" }}
                />
              </ImageContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <HeroSubTitle
                style={{
                  color: "#010101",
                  fontSize: "40px",
                  marginBottom: "20px",
                  fontWeight: "900",
                  textAlign: "left",
                }}
              >
                Experiential Learning Module
              </HeroSubTitle>
              <ol>
                <StyledLi>
                  The Fellows will be deputed to senior political leaders
                  subject to successful completion of the Theoretical Learning
                  Module with their performance reflecting the nature of the
                  appointment they receive, with the very best Fellows receiving
                  appointments at the offices of chief ministers and
                  high-ranking union ministers.
                </StyledLi>
                <StyledLi>
                  Fellows will carry out a variety of tasks in the offices of
                  elected representatives and administrators. In doing so they
                  will acquire hands-on experience of working in a political
                  office.
                </StyledLi>
                <StyledLi>
                  Fellows may have to work on tasks such as-
                  <ul>
                    <StyledLi>
                      Providing inputs for bills under discussion, parliamentary
                      questions and general policy discussions.
                    </StyledLi>
                    <StyledLi>
                      Providing background research for meetings, conferences,
                      speeches and other media events.
                    </StyledLi>
                    <StyledLi>
                      Researching a diverse set of topics depending on the
                      requirements of the office.
                    </StyledLi>
                    <StyledLi>
                      Helping manage a political leader's social media and other
                      communications.
                    </StyledLi>
                    <StyledLi>
                      Writing issues and communication briefs and helping with
                      documentation.
                    </StyledLi>
                    <StyledLi>
                      Helping manage political campaigns for the leader.
                    </StyledLi>
                    <StyledLi>
                      Liaise with other consultants from across the industry on
                      avenues such as policy formulation, political campaigning
                      and legislative research.
                    </StyledLi>
                  </ul>
                </StyledLi>
              </ol>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="flex flex-wrap items-center w-full py-16 mx-auto max-w-7xl sm:pt-20 md:pt-16 lg:py-28 xl:pt-28">
        <div className="flex flex-row w-full mx-auto max-w-7xl lg:px-0">
          <h4 className="mt-6 mb-24 text-[40px] font-[900] text-black md:mt-0">
            Testimonials
          </h4>
        </div>
        <Testimonial data={testimonials} />
      </div>
      <FellowShipContainer>
        <Container maxWidth="xl">
          <Stack spacing={5} justifyContent="center" maxWidth="600px">
            <TitleText style={{ textAlign: "left" }}>
              What is the status of the Fellowship?
            </TitleText>
            <DividerWithLine>
              <DividerBlock style={{ background: "#FF391E" }} />
            </DividerWithLine>
            <HeroSubTitle style={{ fontSize: "26px" }}>
              For the years 2024-25, the Fellowship will start on 1st August
              2024.
            </HeroSubTitle>
            <HeroSubTitle style={{ fontSize: "26px" }}>
              The final date of application will be 25th July 2024.  
            </HeroSubTitle>
            <img
              src={designedByLogo}
              alt="ppgf"
              style={{ maxWidth: "250px" }}
            />
          </Stack>
        </Container>
        <RibbonContainerRight />
      </FellowShipContainer>
    </div>
  );
}
export default withRouter(Home);
