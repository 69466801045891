import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Containers/Home'
import FaqPage from './Containers/FaqPage'
import ApplyNowPage from './Containers/ApplyNowPage'
import './App.css';
import Footer from './components/Footer'
import Header from './components/Header'

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/faq" component={FaqPage} />
        {/* <Route exact path="/apply" component={ApplyNowPage} /> */}
        <Route path="/" component={Home} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
