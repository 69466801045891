import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import { Stack, Button, IconButton } from "@mui/material";
import mukherjeeLogo from '../../assets/images/mukherjee-logo.png';

const HidePhone = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const HideBigScreen = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const CustomHyperLink = styled(Link)`
  color: #101010;
  font-size: 20px;
  font-weight: 600;
  text-decoration: none;
`;
const StyledButton = styled(Button)`
  background: #ff381e !important;
  border-radius: 5px !important;
  color: white !important;
  font-family: Montserrat !important;
  font-size: 20px !important;
  @media (max-width: 768px) {
    font-size: 17px !important;
  }
`;
function Header(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    const unlisten = props.history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [props.history]);

  return (
    <div
      style={{
        position: "fixed",
        top: "0px",
        zIndex: "11",
        background: "#ffffff",
        width: "100%",
      }}
    >
      <Container maxWidth="xl">
        <div style={{ padding: "20px 0px" }}>
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={4}
          >
            <Link to="/">
              <img src={mukherjeeLogo} alt="mukherjee logo" height="43px" width="100px" />
            </Link>
            <HidePhone>
              <Stack spacing={4} alignItems="center" direction="row">
                <CustomHyperLink to="/">Home</CustomHyperLink>
                <CustomHyperLink to="/faq">FAQ</CustomHyperLink>
                <StyledButton
                  variant="contained"
                  // onClick={() => props.history.push("/apply")}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeoI2xMFWQfgvJCUPKEM8wMjMvvGLiPYUI5wkwx56j9OM1_2g/viewform"
                  target="_blank"
                >
                  APPLY NOW
                </StyledButton>
              </Stack>
            </HidePhone>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
            >
              <MenuItem>
                <CustomHyperLink to="/">Home</CustomHyperLink>
              </MenuItem>
              <MenuItem>
                <CustomHyperLink to="/faq">FAQ</CustomHyperLink>
              </MenuItem>
            </Menu>
            <HideBigScreen>
              <Stack spacing={2} alignItems="center" direction="row">
                <StyledButton
                  variant="contained"
                  // onClick={() => props.history.push("/apply")}
                  href="https://docs.google.com/forms/d/e/1FAIpQLSeoI2xMFWQfgvJCUPKEM8wMjMvvGLiPYUI5wkwx56j9OM1_2g/viewform"
                  target="_blank"
                >
                  APPLY
                </StyledButton>
                <IconButton onClick={handleClick}>
                  <MenuIcon />
                </IconButton>
              </Stack>
            </HideBigScreen>
          </Stack>
        </div>
      </Container>
    </div>
  );
}
export default withRouter(Header);
