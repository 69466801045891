export const testimonials = [
  {
    name: "Office of Shri Piyush Goyal",
    designation:
      "Leader of the House, Rajya Sabha; Union Cabinet Minister of Textiles, Consumer Affairs, Food and Public Distribution",
    image:
      "https://assets.weforum.org/sf_account/image/nVjQPWY6VarFZMKpIlq5KmbrYDqa0N66ZzcDg6vshw8.jpg",
    testimonial:
      "The Fellows in our office worked closely on the ground with volunteers from various allied organisations. Their knowledge of the Marathi electorate was invaluable to our team, greatly contributing to the election campaign. We found the Fellows to be a key value add, particularly due to their proficiency in Marathi. We are considering the option of permanently integrating the two into our office.",
  },
  {
    name: "Office of Shri Sarbananda Sonowal",
    designation:
      "Minister of State (Independent Charge) Ports, Shipping and Waterways; & AYUSH",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/6/65/Chief_Minister_of_Assam_Sarbananda_Sonowal.jpg",
    testimonial:
      "The Fellow has shown remarkable maturity and an evident hunger to learn during her fellowship. We believe her institutional knowledge is invaluable, and it could be beneficial to engage her on a longer-term basis to prevent this knowledge from being lost due to the short engagement period. Additionally, we are exploring the option of absorbing more Fellows if the Fellowship program is continued.",
  },
  {
    name: "Office of Shri Nayab Singh Saini",
    designation: "Chief Minister, Haryana",
    image: "nayabsaini.jpg",
    testimonial:
      "The Fellow has exhibited outstanding political communication skills and has been praised for her tenacity and quick learning. She is highly receptive to feedback, and her presentation of data and issues as briefs has been very effective.",
  },
  {
    name: "Office of Shri Baijayant ‘Jay’ Panda ",
    designation: "Member of Parliament, Kendrapara",
    image:
      "https://www.bjp.org/files/leader-profile-images/baijayant%20jaypanda%281%29.png.jpeg",
    testimonial:
      "The Fellow has shown exceptional reliability and zeal throughout her fellowship. During the General Elections of 2024, she skillfully managed the entire Assembly Constituency of Mahanga, demonstrating her capability and dedication. The Fellow’s overall performance has been commendable. We are open to considering her “ permanent placement in our office.",
  },
  {
    name: "Office of Smt. Vanathi Srinivasan",
    designation: "MLA, Tamil Nadu",
    image: "VanathiSriNivasan.jpg",
    testimonial:
      "The Fellow has been sincere and proactive, with a commendable understanding of the boundaries and decorum to be followed in the Leader's office. She has efficiently handled all legislative work for the office. I believe that future Fellows should be taught to develop outreach programs to enhance their contributions further.",
  },
];
